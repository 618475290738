<template>
    <Transition name="fade">
        <div class="TransitionVideos" v-show="isEnabled">
            <video ref="transitionVideo" :src="videoSrc" muted autoplay/>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Flux360LogicSingleton } from "../logic/Flux360LogicSingleton";
import { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";

//BABYLON Import
import { ExecuteCodeAction } from '@babylonjs/core/Actions/directActions';
import { ActionManager } from "@babylonjs/core/Actions/actionManager";

let isEnabled = ref(true);
let videoSrc = ref("");
let transitionVideo = ref<HTMLVideoElement | null>(null);

Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationComponentCreated((situationComponent:SituationComponent) => {
    if(situationComponent.getConfig().type == "Transition"){
        let scene =  Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene();

        // @ts-ignore
        let plane = situationComponent.rootNode.getChildren()[0] as Mesh;
        plane.actionManager = new ActionManager(scene);
        plane.actionManager!.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger,() => {
            if(Flux360LogicSingleton.getInstance().getTour().getActiveSituationID() != 10)
                Flux360LogicSingleton.getInstance().getLifeCycleHooks().triggerOnSituationComponentHoverOver(situationComponent);
        }));
        plane.actionManager!.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger,() => {
            Flux360LogicSingleton.getInstance().getLifeCycleHooks().triggerOnSituationComponentHoverOut(situationComponent);
        }));
        plane.actionManager!.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, async () => {
            Flux360LogicSingleton.getInstance().getLifeCycleHooks().triggerOnSituationComponentClicked(situationComponent);

            let activeSituationID = Flux360LogicSingleton.getInstance().getTour().getActiveSituationID();
           
            // Change Video src
            videoSrc.value = "/src/assets/videos/" + getVideoName(activeSituationID);
            
            // Show this element on play
            isEnabled.value = true;

            // Hide this element on ended
            transitionVideo.value!.addEventListener("ended", () => {

                if(activeSituationID != 10){
                    isEnabled.value = false;
                    Flux360LogicSingleton.getInstance().getTour().changeSituation(activeSituationID + 1, {playAnimation: false});
                }
                if(activeSituationID == 10){
                    window.postMessage("company_tour_done");
                }
            }, { once: true });
        }));
    }
});
    
Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationEnter((newSituationID: number, oldSituationID: number|undefined) => {   
    if(newSituationID == 1){
        videoSrc.value = "/src/assets/videos/0-0_Spritzerei.mp4";
        isEnabled.value = true;
        transitionVideo.value!.addEventListener("ended", () => {
            isEnabled.value = false;
        }, { once: true });
    }
});

let getVideoName = (activeSituationID:number) => {
    let videoName = "";
    if(activeSituationID == 1)
        videoName = "0-1_Spritzerei-Armaturenmontage.mp4";
    if(activeSituationID == 2)
        videoName = "1-2_Armaturenmontage-Werkzeugbau.mp4";
    if(activeSituationID == 3)
        videoName = "2-3_Werkzeugbau-Fräserei.mp4";
    if(activeSituationID == 4)
        videoName = "3-4_Fräserei-Messraum.mp4";
    if(activeSituationID == 5)
        videoName = "4-5 Messraum-Werzeugeinstellung.mp4";
    if(activeSituationID == 6)
        videoName = "5-6 Werzeugeinstellung_MechanischeFertigung.mp4";
    if(activeSituationID == 7)
        videoName = "6-7 MechanischeFertigung_Pumpenmontage.mp4";
    if(activeSituationID == 8)
        videoName = "7-8 Pumpenmontage_Lackiererei.mp4";
    if(activeSituationID == 9)
        videoName = "8-9 Lackiererei_Sägerei.mp4";
    if(activeSituationID == 10)
        videoName = "9-9_Sägerei-Ende.mp4";

    return videoName;
}
</script>

<style lang="scss" scoped>
.TransitionVideos{
    position: absolute;
    z-index: 1;

    video{
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        object-position: bottom;
    }
}

.fade-leave-active{
  transition: opacity 1.5s ease;
}
.fade-enter-active {
  transition: opacity 1s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>