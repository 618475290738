import { name,version } from "../package.json";
import { Component } from 'vue';
import { Constants } from "@babylonjs/core/Engines/constants";
import { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";

// Logic & Interface
import { Flux360Logic } from 'flux-360-logic';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import { Flux360Interface } from 'flux-360-interface';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

// Configs
import logicConfig from '@/configs/config_logic.json';
import interfaceConfig from '@/configs/config_interface.json';

// New Components
import TransitionVideos from "./components/MouseAnimation.vue";
import MouseAnimation from "./components/TransitionVideos.vue";

// Loc Version
console.log("---- Starting " + name + " [" + version + "] ----");
console.log("");

// Create Logic & Interface
let flux360Logic = new Flux360Logic(logicConfig);
let flux360Interface = new Flux360Interface(interfaceConfig);
Flux360LogicSingleton.setInstance(flux360Logic);
Flux360InterfaceSingleton.setInstance(flux360Interface);

// Setup Interface
flux360Interface.setup(flux360Logic, new Map<string, Component>([]), [TransitionVideos, MouseAnimation]);

// Remove Menubar
flux360Interface.getLifeCycleHooks().registerOnAfterSetup(() => {
    document.getElementById("Menubar")!.style.display = "none";
})

// Zoom out for Mobile & Desktop
flux360Logic.getLifeCycleHooks().registerOnAfterSetup(() => {
    let camera = flux360Logic.getGraphicsEngine().getScene().activeCamera;
    camera.fov = 0.7;
    if(flux360Interface.getInterfaceStore().isMobile){
        camera.fov = 1.5; // Mobile
        camera.invertRotation = true;
    }
});

// Change Alpha Mode of Materials
Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationComponentCreated((situationComponent:SituationComponent) => {
    if(situationComponent.getConfig().type == "Image" || situationComponent.getConfig().type == "Transition"){
        // @ts-ignore
        let plane = situationComponent.rootNode.getChildren()[0] as Mesh;
        plane.material.alphaMode = Constants.ALPHA_COMBINE;
    }
});