<template>
    <div v-if="isVisible">
        <img src="@/assets/icons/MoveMouse.gif" alt="">
        <p>Bewegen Sie Ihre Maus mit gedrückter linken Maustaste</p>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Flux360LogicSingleton } from "../logic/Flux360LogicSingleton";
import { Engine } from "@babylonjs/core/Engines/engine";

let isVisible = ref(true);

onMounted(()=>{
    Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnEngineCreated((engine:Engine) => {
        engine.getRenderingCanvas()!.addEventListener("pointerdown", () => {
            isVisible.value = false;
        }, { once: true });
    });
});
</script>

<style lang="scss" scoped>
div{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);
    flex-direction: column;
}
img{
    width: 200px;
    margin-bottom: 10px;
}
p{
    margin: 0;
    color: white;
    width: 300px;
    text-align: center;
    font-weight: bold;
}
</style>